// title: Cube Set Prospectives
// frameCardName: Thoughtseize
// frameCardSet: LRW

import * as styles from './index.module.scss'

import React from 'react'
import { Link, graphql, PageProps } from 'gatsby'

import Layout from 'components/layout/Layout'
import PageMetaData from 'components/layout/PageMetaData'
import * as Typography from 'components/typography'
import * as Table from 'components/tables'

import SetsTable from 'src/components/set-prospectives/SetsTable'

interface Props extends PageProps<Queries.SetProspectivesPageQuery> {
  pageContext: any
}

const SetProspectivesPage: React.FC<Props> = (props) => {
  const { sets } = props.data.allMdx

  return (
    <Layout
      {...props.pageContext}
      accentColor="#AF51A9"
      includeNewsletterSignup
    >
      <div className={styles.container}>
        <h1>
          <Typography.PrimaryHeading>
            Set Prospectives
          </Typography.PrimaryHeading>
        </h1>

        {sets != null && (
          <div className={styles.setsTable}>
            <SetsTable sets={sets} />
          </div>
        )}

        <h2>
          <Typography.SecondaryHeading>Purpose</Typography.SecondaryHeading>
        </h2>
        <Typography.Paragraph>
          For each new <i>Magic: the Gathering</i> set we conduct a survey of
          Cube designers to collect information about which new cards they plan
          on playing in their cubes. While there is no shortage of subjective
          set reviews written by individuals, our aim to paint a more broad
          picture of how a new set is perceived. This allows players to compare
          their own evaluations to the community at large and is a resource for
          those who aren&rsquo;t able to review the whole set themselves.
        </Typography.Paragraph>
        <Typography.Paragraph>
          Our survey is conducted between the set&rsquo;s full-spoiler and
          official release. We believe there is a unique value in recording
          people&rsquo;s first impressions of new cards before they have had
          time to play with them extensively. In the long run, we hope to use
          the data we&rsquo;ve collected to examine how perceptions of card
          power-level change over time in the hopes of building a more robust
          and self-aware understanding of the game.
        </Typography.Paragraph>

        <Typography.Paragraph>
          <Link to="/set-prospectives/archive-search/">
            Search past submissions
          </Link>
        </Typography.Paragraph>

        <h2>
          <Typography.SecondaryHeading>Methodology</Typography.SecondaryHeading>
        </h2>
        <Typography.Paragraph>
          Our approach is simple: we ask Cube designers to share a ranked list
          of cards they plan on testing in their own cube or cubes from each new
          set. The cards are scored as follows:
        </Typography.Paragraph>

        <Table.Table textWidth>
          <Table.TableRow>
            <Table.TableHeaderCell align="center">Rank</Table.TableHeaderCell>
            <Table.TableHeaderCell align="left">
              Description
            </Table.TableHeaderCell>
          </Table.TableRow>
          <Table.TableRow>
            <Table.TableDataCell align="center">1</Table.TableDataCell>
            <Table.TableDataCell>
              Low grade. Cards you are testing, but don&rsquo;t think will make
              the cut long-term.
            </Table.TableDataCell>
          </Table.TableRow>
          <Table.TableRow>
            <Table.TableDataCell align="center">5</Table.TableDataCell>
            <Table.TableDataCell>
              Medium grade. Cards you believe could have staying power in your
              cube, but aren&rsquo;t slam dunks.
            </Table.TableDataCell>
          </Table.TableRow>
          <Table.TableRow>
            <Table.TableDataCell align="center">10</Table.TableDataCell>
            <Table.TableDataCell>
              High grade. Cards you think will be staples of your cube for years
              to come.
            </Table.TableDataCell>
          </Table.TableRow>
        </Table.Table>

        <Typography.Paragraph>
          These rankings are subjective and cube designers test cards by
          different metrics. For example, a respondent may think a card is
          powerful, but give it a low grade if they are worried it will be too
          oppressive in their cube, or if they maintain strict numbers of cards
          in specific guilds or mana values and don&rsquo;t think there will be
          room for it. Conversely, less powerful cards may be ranked highly if
          they provide an effect that a player is especially interested in
          adding to their cube. Our goal is <i>not</i> to somehow quantify the
          &ldquo;best&rdquo; Cube cards, but instead to celebrate diverse
          approaches to the format.
        </Typography.Paragraph>
        <Typography.Paragraph>
          Cards are summarized by their average rating and their consensus
          value, which is an indication of how much users agree on a
          card&rsquo;s rating. The consensus of a card is just 1 – σ, where σ is
          the standard deviation of the card&rsquo;s rating across users testing
          it. Because the rating is from 1 – 10, the consensus ranges from 0 –
          1. Note that not all combinations of consensus and average rating are
          possible.
        </Typography.Paragraph>
        <Typography.Paragraph>
          Participants in our surveys are Cube designers that subscribe to{' '}
          <a href="/newsletter">our newsletter</a>, follow us on social media,
          or are members of one of the following communities, to which we
          specifically reach out:
        </Typography.Paragraph>

        <Typography.UnorderedList>
          <li>
            <a href="https://www.reddit.com/r/mtgcube/">
              MTG Cube Subreddit — r/mtgcube
            </a>
          </li>
          <li>
            <a href="https://www.mtgsalvation.com/forums/the-game/the-cube-forum">
              MTGSalvation
            </a>
          </li>
          <li>
            <a href="https://discordapp.com/invite/tFBZ2Z3">
              MTG CubeTalk Discord
            </a>
          </li>
          <li>
            <a href="https://discord.gg/4eHADtb">XMage Cube Group Discord</a>
          </li>
          <li>
            <a href="https://discord.gg/HeJsGUc">
              MTG Cube Brainstorming Discord
            </a>
          </li>
          <li>
            <a href="https://riptidelab.com/forum/forums/cube-talk.5/">
              RiptideLab Cube Talk Forum
            </a>
          </li>
        </Typography.UnorderedList>

        <Typography.Paragraph>
          If you&rsquo;re an organizer or member of a Cube community not listed
          here and would like to participate in future surveys drop us a note at{' '}
          <a href="mailto:mail@luckypaper.co">mail@luckypaper.co</a>.
        </Typography.Paragraph>
        <Typography.Paragraph>
          The Lucky Paper set prospectives are conceived by founding contributor
          Jett Crowdis and written by Jett and Parker LaMascus with technical
          support from Anthony Mattox.
        </Typography.Paragraph>

        <Typography.SecondaryDivider />

        <Typography.TertiaryHeading>
          Rating Scale Note
        </Typography.TertiaryHeading>

        <Typography.Paragraph>
          Starting with{' '}
          <Link to="/articles/cube-prospective-march-of-the-machine/">
            March of the Machine
          </Link>{' '}
          the rating scale changed from 1-3 to 1-10. The previous rating system
          was less intuitive for many participants. Prior data sets have been
          re-scaled to match the new rating system. Both scales allowed
          fractional values, so in practice this was a minor change, but it
          could have had some effect on the way participants rate cards.
        </Typography.Paragraph>

        <p></p>
        <Typography.Divider />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query SetProspectivesPage {
    allMdx(
      sort: { frontmatter: { date: DESC } }
      filter: { frontmatter: { series: { eq: "Set Prospective" } } }
    ) {
      sets: nodes {
        id
        frontmatter {
          title
          date(formatString: "MMMM Do, YYYY")
          prospectiveSurveySet {
            code
            iconURL
          }
        }
        fields {
          slug
        }
      }
    }
  }
`

export const Head: React.FC<Props> = (props) => {
  return (
    <PageMetaData
      url={props.pageContext.url}
      metaData={{
        title: `Cube Set Prospectives — Lucky Paper`,
        description:
          'Lucky Paper surveys the Cube community with each new Magic set to find out the most popular and contentions cards. Results for each set are compiled and visualized.',
        ogImageURL: 'https://luckypaper.co/lucky-paper.jpg',
      }}
    />
  )
}

export default SetProspectivesPage
