import * as styles from './SetsTable.module.scss'

import React from 'react'
import { Link } from 'gatsby'

interface Props {
  sets: readonly {
    id: string
    frontmatter: {
      title: string
      date: string | null
      prospectiveSurveySet: {
        code: string | null
        iconURL: string | null
      } | null
    } | null
    fields: {
      slug: string | null
    } | null
  }[]
}

const SetsTable: React.FC<Props> = (props) => {
  const { sets } = props

  return (
    <div>
      {sets.map((set) => {
        if (set.frontmatter?.title == null) {
          return null
        }
        return (
          <Link key={set.id} to={set.fields?.slug ?? ''} className={styles.row}>
            <div className={styles.symbolContainer}>
              {set.frontmatter?.prospectiveSurveySet?.iconURL && (
                <img
                  className={styles.symbol}
                  src={set.frontmatter.prospectiveSurveySet.iconURL}
                  alt=""
                />
              )}
            </div>
            <div className={styles.info}>
              <h2 className={styles.title}>{set.frontmatter.title}</h2>
              <div>{set.frontmatter.date}</div>
            </div>
          </Link>
        )
      })}
    </div>
  )
}

export default SetsTable
